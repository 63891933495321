import React from 'react';
import { Link } from 'gatsby';
import { Header } from 'semantic-ui-react';
import Layout from '../components/layout';
import Helmet from '../components/Helmet';
import * as DefaultTemplate from '../components/ContentPages/DefaultTemplate';

const AboutPage = props => (
    <Layout
        showDisclosures={false}
    >
        <Helmet
            title="About"
            description="Offering solutions to help you get out of debt including credit counseling, debt settlement, debt consolidation, useful articles and other consumer resources."
            robots="noindex, follow"
        />
        <DefaultTemplate.Wrapper>
            <DefaultTemplate.Body>
                <Header as="h1" size="large">
                    About
                </Header>
                <Header as="h2" size="medium" className="no-margin">
                    What is DebtConsolidation.com?
                </Header>
                <p>
                    DebtConsolidation.com is single-source website launched in 2012 to help consumers by providing the information, tools and solutions needed to get out, and stay out, of debt.
                </p>
                <Header as="h2" size="medium" className="no-margin">
                    Our Mission
                </Header>
                <p>
                    Our purpose is simple: To help individuals and families escape the overwhelming burden of debt.
                </p>
                <p>
                    Debt is a serious problem that many people struggle with. It can leave individuals and families hopeless and desperate, and can lead to the loss of homes, cars, and even relationships. The good news is that solutions are available to help people keep their sanity, and their possessions.
                </p>
                <p>
                    At Debtconsolidation.com, we help consumers by providing the information, tools and solutions needed to get out, and stay out, of debt. We have interesting and helpful articles about budgeting, managing debt, making the most of your money and many more. Our 
                    {' '}
                    <Link to="/how-to-get-out-of-debt/">Learn About Debt</Link>
                    {' '}
                    resource page provides a wealth of consumer information, while our solution pages offer services that you may be looking for, including
                    {' '}
                    <Link to="/loans/">debt consolidation loans</Link>
                    ,
                    {' '}
                    <Link to="/credit-counseling/">credit counseling</Link>
                    {' '}
                    and
                    {' '}
                    <Link to="/debt-settlement/">debt settlement</Link>
                    . If you’re in debt, or trying to stay out of debt, we can help. So go ahead and take a look around.
                </p>
            </DefaultTemplate.Body>
        </DefaultTemplate.Wrapper>
    </Layout>
);

export default AboutPage;
